.page-home {
  display: flex;
  flex-direction: column;
  .home__hero {
    display: flex;
    padding: 15px;
    justify-content: center;
    margin: auto;
    width: 70%;
    img {
      height: 300px;
      margin-right: 30px;
    }
  }
  .page-home__card {
    width: 100%;
    max-width: 400px;
  }
  .home__albums {
    padding-bottom: 75px;
    display: flex;
    flex-wrap: wrap;
    .album__container {
      display: flex;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      a {
        width: 100%;
      }
    }
  }
}