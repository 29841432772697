.video {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-width: 600px;
  max-height: 332px;
  border-radius: 25px !important;
  @media screen and (max-width: 600px) {
    height: auto;
    width: 100vw;
    max-width: none;
    max-height: none;
  }
  overflow: hidden;
  background-color: transparent !important;
  video {
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
