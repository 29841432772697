.page-about {
  padding-bottom: 15px;
  .about__card {
    max-width: 500px;
    width: 100%;
    margin: 0 auto 15px auto;
    iframe {
      margin: 15px -15px 15px -15px;
      height: 300px;
      width: calc(100% + 30px);
      border: 0;
    }
  }
}