.album {
  flex: 1;
  width: 100%;
  min-width: 300px;
  margin: 0 auto 15px auto;
  opacity: 0.9;
  a {
    text-decoration: none;
    color: inherit;
    position: relative;
    top: 3px;
  }
}